import React, {useState} from 'react';
import Button from "../components/Button";
import {graphql, Link, useStaticQuery} from "gatsby"
import {StaticImage} from "gatsby-plugin-image"
import MobileNavBar from "./MobileNavBar";

function getNavbarItems(data) {
    return data.allNavbarJson.edges.map(item =>{
        if(item.node.path.startsWith("http"))
            return (
                <a
                    key={item.node.id}
                    className="hover:text-indigo-500 transition text-white"
                    href={item.node.path}
                >
                    {item.node.name}
                </a>
            )
        return (
            <Link
                key={item.node.id}
                className="hover:text-indigo-500 transition text-white"
                to={item.node.path}
                activeClassName="text-indigo-500"
            >
                {item.node.name}
            </Link>
        )
    }


    )
}

function NavBar() {
    const [mobileNavbar, setMobileNavbar] = useState(false)
    const data = useStaticQuery(graphql`
        query getNavbarDataQuery {
            allNavbarJson {
                edges {
                    node {
                        name
                        path
                        id
                    }
                }
            }
            contentJson {
                calendlyUrl
            }
        }
    `)

    return (
        <div
            className="bg-gray-800 backdrop-filter backdrop-blur shadow-md sm:shadow-none bg-opacity-75 fixed py-4 lg:pb-4 z-50 w-screen lg:py-4">
            <nav className="relative max-w-screen-xl mx-auto flex items-center justify-between px-4 sm:px-6">
                {/*<h1 className="logo text-3xl text-white">Pratik Daigavane</h1>*/}
                <div className="flex items-center justify-between w-72">
                    <Link to="/">
                        <StaticImage
                            src="../images/header.png"
                            alt="Logo"
                            placeholder="tracedSVG"
                        />
                    </Link>
                </div>
                <div className="hidden lg:flex lg:justify-center lg:gap-10 lg:items-center">
                    <div className="space-x-10 text-l">
                        {getNavbarItems(data)}
                    </div>
                    <div className="justify-self-end">
                        <Button url={data.contentJson.calendlyUrl} target="_blank" rel="noreferrer"><span role="img"
                                                                                         aria-label="Coffee">☕</span>Let's
                            Talk</Button>
                    </div>
                </div>
                <div role="button" tabIndex={0} className="lg:hidden" onClick={() => setMobileNavbar(!mobileNavbar)} onKeyDown={() => setMobileNavbar(!mobileNavbar)}>
                    <button
                        className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:bg-gray-700 focus:outline-none focus:bg-gray-700 transition duration-150 ease-in-out">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                             stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                  d="M4 6h16M4 12h16M4 18h16"/>
                        </svg>
                    </button>
                </div>
            </nav>
            <div>
                <MobileNavBar visible={mobileNavbar} toggle={setMobileNavbar} data={data}/>
            </div>
        </div>
    )

        ;
}

export default NavBar;